import React, { useEffect } from "react";
import Main from '../components/Home/Main';
import Nap from '../components/Home/Nap';
import Ticker from '../components/Home/Ticker';
import About from '../components/Home/About';
import Vacancy from '../components/Home/Vacancy';
import Rating from '../components/Home/Rating';
import Form from '../components/Home/Form';

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-white'>
      <Main/>
      <Ticker/>
      <Nap/>
      <About/>
      <div id="vacancy">
        <Vacancy/>
      </div>
      <Form/>
      <div id="rating">
        <Rating/>
      </div>
    </div>
  );
}

export default Home;