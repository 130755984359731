import React, { useEffect, useState } from "react";
import Header from '../components/Header';
import { useTranslation, Trans } from "react-i18next";

import one from '../assets/img/Consalting/1.jpg'
import two from '../assets/img/Consalting/2.jpg'
import three from '../assets/img/Consalting/3.jpg'

const data = [
  {
    title: "consalting.2",
    img: one,
    text1: 'consalting.5',
    text2: 'consalting.6',
    text3: 'consalting.7',
    text4: 'consalting.8',
    text5: 'consalting.9',
    text6: 'consalting.10',
    text7: 'consalting.11',
    text8: 'consalting.12',
    text9: 'consalting.13',
    text10: 'consalting.14',
    text11: 'consalting.15',
  },
  {
    title: "consalting.3",
    img: two,
    text1: 'consalting.16',
    text2: 'consalting.17',
    text3: 'consalting.18',
    text4: 'consalting.19',
    text5: 'consalting.20',
    text6: 'consalting.21',
    text7: 'consalting.22',
    text8: 'consalting.23',
    text9: 'consalting.24',
    text10: 'consalting.25',
    text11: 'consalting.26',
    text12: 'consalting.27',
    text13: 'consalting.28',
    text14: 'consalting.29',
    text15: 'consalting.30',
    text16: 'consalting.31',
  },
  {
    title: "consalting.4",
    img: three,
    text1: 'consalting.32',
    text2: 'consalting.33',
    text3: 'consalting.34',
    text4: 'consalting.35',
    text5: 'consalting.36',
    text6: 'consalting.37',
    text7: 'consalting.38',
  },
]

function Consalting() {

  const { t } = useTranslation();

  const [active, setActive] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-white'>
      <Header />
      <div className='max-w-[1280px] xl:mx-auto mx-[20px]'>
        <p className='spancons rubik-font font-[700] max-w-[300px] xl:max-w-[488px] xl:leading-[41.76px] text-[20px] xl:text-[36px] text-black mt-[40px]'><Trans>{t('consalting.1')}</Trans></p>
        <div className='flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px]'>
          <div className='flex flex-col sm:flex-row xl:flex-col gap-0 sm:gap-[20px] xl:gap-0'>
            {data.map((dat, index) => (
              <div className={`w-full xl:w-[383px] h-[55px] flex items-center sm:justify-center xl:justify-start px-[40px] transition-all cursor-pointer ${active === index ? 'bg-[#40DDB6]' : 'border-b'}`} key={index} onClick={() => setActive(index)}>
                  <p className='rubik-font text-[16px] xl:text-[20px] text-[#212121] font-[500]'>{t(dat.title)}</p>
              </div>
            ))}
          </div>
          <div className='mt-[40px] xl:mt-0'>
            <img src={data[active].img} alt="" className='mx-auto'/>
            <div className='xl:max-w-[697px]'>
              {data[active].text1 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[40px]'>
                  <Trans>{t(data[active].text1)}</Trans>
                </p>
              )}
              {data[active].text2 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text2)}</Trans>
                </p>
              )}
              {data[active].text3 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] mt-[12px]'>
                  <Trans>{t(data[active].text3)}</Trans>
                </p>
              )}
              {data[active].text4 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] mt-[12px]'>
                  <Trans>{t(data[active].text4)}</Trans>
                </p>
              )}
              {data[active].text5 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[40px]'>
                  <Trans>{t(data[active].text5)}</Trans>
                </p>
              )}
              {data[active].text6 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text6)}</Trans>
                </p>
              )}
              {data[active].text7 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[12px]'>
                  <Trans>{t(data[active].text7)}</Trans>
                </p>
              )}
              {data[active].text8 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[12px]'>
                  <Trans>{t(data[active].text8)}</Trans>
                </p>
              )}
              {data[active].text9 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[12px]'>
                  <Trans>{t(data[active].text9)}</Trans>
                </p>
              )}
              {data[active].text10 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[40px]'>
                  <Trans>{t(data[active].text10)}</Trans>
                </p>
              )}
              {data[active].text11 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text11)}</Trans>
                </p>
              )}
              {data[active].text12 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text12)}</Trans>
                </p>
              )}
              {data[active].text13 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text13)}</Trans>
                </p>
              )}
              {data[active].text14 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text14)}</Trans>
                </p>
              )}
              {data[active].text15 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text15)}</Trans>
                </p>
              )}
              {data[active].text16 && (
                <p className='rubik-font text-[14px] xl:text-[16px] text-[#212121] spanbold mt-[20px]'>
                  <Trans>{t(data[active].text16)}</Trans>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consalting;