import React from 'react';

import five from '../../assets/img/agency_img1.jpg'
import eight from '../../assets/img/imggggg.jpg'
import Fade from 'react-reveal/Fade';

import { useTranslation } from "react-i18next";

import Header from '../Header'

function About() {

  const { t } = useTranslation();
  return (
    <>
    <div className='container flex mx-auto flex-col xl:flex-row xl:mt-[140px] md:mt-[60px]'>
        <div className='w-[339px] sm:w-[540px] xl:ml-[170px] md:ml-[50px] xl:pb-[0px] md:pb-[40px] ml-[20px] mt-[60px]'>
                <Fade left delay={500}><div className='flex'>
                    <h1 className='text-[25px] w-[216px] sm:w-full mt-[20px] sm:mt-[0px] sm:text-[50px] rubik-font font-bold'>{t('06global_industry_leadership')}</h1>
                </div></Fade>
                <Fade left delay={1000}><div className='mt-[21px] sm:mt-[35px]'>
                    <p className='rubik-font text-[#212121] text-[14px] sm:text-[16px] font-light'>{t('07market_analysis')}</p>
                </div></Fade>
                <Fade left delay={1500}><a href="https://t.me/GlobalSupport202" target="_blank"><div className='button mt-[50px] mx-auto xl:mx-0'>
                <p>{t('main_button')}</p>
                </div></a></Fade>
        </div>
        {/* Mobile Block */}
        <div className='sm:hidden'>
            <Fade bottom><img src={five} alt="" className='px-[20px] mt-[20px]'/></Fade>
        </div>
        {/* Mobile Block */}      
        <div className='xl:ml-[170px] hidden sm:block'>
            <div className='flex'>
                <Fade bottom><img src={five} alt="" /></Fade>
            </div>
        </div>   
    </div>
    <div className='container flex mx-auto mt-[140px] hidden sm:hidden xl:flex' id="service">
        <div className='ml-[170px]'>
            <h1 className='text-[50px] rubik-font text-[#212121] font-bold'>{t('08our_services')}</h1>
            <p className='mt-[60px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('09marketing_research')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('10market_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('11analytical_market_research')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('12market_analytical_review')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('13competitor_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('14market_passport')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('15production_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('16sales_analysis_of_goods_and_services')}</p>
        </div>
        <div className='ml-[354px]'>
            <p className='mt-[135px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('17external_trade_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('18market_research_for_legal_companies')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('19market_research_and_strategy_for_business_idea')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('20price_and_assortment_monitoring_in_stores')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('21business_database')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('22market_analysis_during_crisis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('23financial_consulting')}</p>
        </div>
    </div>
     {/* Mobile,Tablet Block */}    
    <div className='container flex mx-auto sm:mt-[140px] mt-[60px] xl:hidden block' id="servicetablet">
        <div className='xl:ml-[170px] md:ml-[50px] ml-[20px]'>
            <h1 className='text-[25px] sm:mt-[0px] sm:text-[50px] rubik-font text-[#212121] font-bold'>{t('08our_services')}</h1>
                <p className='mt-[30px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('09marketing_research')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('10market_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('11analytical_market_research')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('12market_analytical_review')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('13competitor_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('14market_passport')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('15production_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('16sales_analysis_of_goods_and_services')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('17external_trade_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('18market_research_for_legal_companies')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('19market_research_and_strategy_for_business_idea')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('20price_and_assortment_monitoring_in_stores')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('21business_database')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('22market_analysis_during_crisis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('23financial_consulting')}</p>
        </div>
    </div>
     {/* Mobile,Tablet Block */}    
    <div className='flex flex-col xl:flex-row mt-[140px]'>
        <div className='xl:w-1/2 h-[525px] bg-[#37393F]'>
            <div className='max-w-[640px] xl:mx-auto ml-[20px]'>
                <div className='flex mt-[30px]'>
                    <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#FFF] rubik-font font-bold '>{t('24business_planning')}</h1>
                </div>
                <div className='mt-[30px]'>
                    <p className='mt-[0px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('25business_plan_development_international_standards')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('26business_plan_for_start-up_projects')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('27investment_project_and_investment_proposal')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('28financial_model_development_business_constructor')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('29from_business_idea_to_successful_business')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('30preparation_of_presentation_business_plan_for_investor')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('31development_of_investment_proposal')}</p>
                    <p className='mt-[15px] text-[#FFF] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('32business_evaluation')}</p>
                </div>
            </div>
        </div>
        <div className='xl:w-1/2 h-[525px]'>
            <img src={eight} className='w-full h-full object-cover' alt="" />
        </div>
    </div>
    </>

  );
}

export default About;