import React from 'react';

import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next"; 
import { Navigation, Autoplay } from 'swiper/modules';
import prev from '../../assets/img/prev.svg';
import next from '../../assets/img/next.svg';
import roman from '../../assets/img/photo.png'
import tetyana from '../../assets/img/photo(1).png'
import andrei from '../../assets/img/photo(5).png'
import stasya from '../../assets/img/photo(3).png'
import xz from '../../assets/img/photo(4).png'
import photo2 from '../../assets/img/photo(2).png'

import 'swiper/css/navigation';
import "swiper/css";

function Rating() {

  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});

  return (
    <div className='xl:pb-[200px] md:pb-[140px] pb-[60px] xl:mt-[50px] mt-[90px]'>
      <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#212121] font-bold text-center rubik-font mt-[140px]'>{t('58customer_reviews')}</h1>

      {/* Tablet Block */}  
      <div className='flex w-[72px] mt-[30px] mb-[8px] xl:hidden flex ml-[calc(100%-120px)]'>
            <img
              onClick={() => swiper.slidePrev()}
              src={prev}
              className='w-[30px] h-[30px] mr-[6px]'
              alt=""
            />
            <img
              onClick={() => swiper.slideNext()}
              src={next}
              className='w-[30px] h-[30px] ml-[6px]'
              alt=""
            />
      </div>
      {/* Tablet Block */}

      <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={2}
        centeredSlides={true}
        spaceBetween={20}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onInit={(e) => {
          setSwiper(e);
        }}
        breakpoints={{
          368: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1280: {
            slidesPerView: 2,
          },
        }}
        className='xl:mt-[60px] md:mt-[15px] absolute'
      >
        <SwiperSlide>
            <div className='bg-white xl:w-[802px] md:w-[688px] w-[339px] h-[332px] sm:h-[264px] mx-auto rounded-[20px]'>
              <div className='flex'>
                <img src={xz} alt="" className='mt-[30px] xl:ml-[303px] md:ml-[245px] ml-[97px]'/>
                <p className='mt-[48px] ml-[20px] roboto-light text-[14px] text-[#000000b3]'>{t('61tetyana_age')}</p>
              </div>
              <p className='roboto-light text-[14px] text-center leading-5 xl:mx-[105px] md:mx-[45px] mt-[15px] text-[#000000b3] w-[320px] mx-auto sm:w-auto'>{t('59customer_review')}</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white xl:w-[802px] md:w-[688px] w-[339px] h-[332px] sm:h-[264px] mx-auto rounded-[20px]'>
            <div className='flex'>
                <img src={roman} alt="" className='mt-[30px] xl:ml-[303px] md:ml-[245px] ml-[97px]'/>
                <p className='mt-[48px] ml-[20px] roboto-light text-[14px] text-[#000000b3]'>{t('60roman_age')}</p>
              </div>
              <p className='roboto-light text-[14px] text-center leading-5 xl:mx-[105px] md:mx-[45px] mt-[15px] text-[#000000b3] w-[320px] mx-auto sm:w-auto'>{t('62recommendation')}</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white xl:w-[802px] md:w-[688px] h-[332px] sm:h-[264px] w-[339px] mx-auto rounded-[20px]'>
            <div className='flex'>
                <img src={andrei} alt="" className='mt-[30px] xl:ml-[303px] md:ml-[245px] ml-[97px]'/>
                <p className='mt-[48px] ml-[20px] roboto-light text-[14px] text-[#000000b3]'>{t('63andriy_age')}</p>
              </div>
              <p className='roboto-light text-[14px] text-center leading-5 xl:mx-[105px] md:mx-[45px] mt-[15px] text-[#000000b3] w-[320px] mx-auto sm:w-auto'>{t('64professional_experience')}</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white xl:w-[802px] md:w-[688px] h-[332px] sm:h-[264px] w-[339px] mx-auto rounded-[20px]'>
            <div className='flex'>
               <img src={photo2} alt="" className='mt-[30px] xl:ml-[303px] md:ml-[245px] ml-[97px]'/>
               <p className='mt-[48px] ml-[20px] roboto-light text-[14px] text-[#000000b3]'>{t('65stanislav_age')}</p>
              </div>
              <p className='roboto-light text-[14px] text-center leading-5 xl:mx-[105px] md:mx-[45px] mt-[15px] text-[#000000b3] w-[320px] mx-auto sm:w-auto'>{t('66thank_you_message')}</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white xl:w-[802px] md:w-[688px] h-[332px] sm:h-[264px] w-[339px] mx-auto rounded-[20px]'>
            <div className='flex'>
               <img src={stasya} alt="" className='mt-[30px] xl:ml-[303px] md:ml-[245px] ml-[97px]'/>
               <p className='mt-[48px] ml-[20px] roboto-light text-[14px] text-[#000000b3]'>{t('67katerina_age')}</p>
              </div>
              <p className='roboto-light text-[14px] text-center leading-5 xl:mx-[105px] md:mx-[45px] mt-[15px] text-[#000000b3] w-[320px] mx-auto sm:w-auto'>{t('68thankful_feedback')}</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white xl:w-[802px] md:w-[688px] h-[332px] sm:h-[264px] w-[339px] mx-auto rounded-[20px]'>
            <div className='flex'>
               <img src={tetyana} alt="" className='mt-[30px] xl:ml-[303px] md:ml-[245px] ml-[97px]'/>
               <p className='mt-[48px] ml-[20px] roboto-light text-[14px] text-[#000000b3]'>{t('maxim')}</p>
              </div>
              <p className='roboto-light text-[14px] text-center leading-5 xl:mx-[105px] md:mx-[45px] mt-[15px] text-[#000000b3] w-[320px] mx-auto sm:w-auto'>{t('maximtext')}</p>
            </div>
        </SwiperSlide>
      </Swiper>
      <div className='flex mx-auto w-[72px] mt-[30px] hidden xl:flex'>
            <img
              onClick={() => swiper.slidePrev()}
              src={prev}
              className='w-[30px] h-[30px mr-[6px]'
            />
            <img
              onClick={() => swiper.slideNext()}
              src={next}
              className='w-[30px] h-[30px] ml-[6px]'
            />
          </div>
    </div>
  );
}

export default Rating;