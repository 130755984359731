import React from 'react';
import { useState } from 'react';
import eight from '../../assets/img/Photo.jpg'

import { useTranslation } from "react-i18next";

import axios from 'axios';

function Form() {

  const { t } = useTranslation();

    // Состояния для полей формы
    const [name, setName] = useState('');
    const [phone, setPhone] = useState(''); // Изменил на phone
    const [lang, setLang] = useState(''); // Изменил на lang
    const [termsAgree, setTermsAgree] = useState(false);

    // Валидация полей
    const [nameValid, setNameValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true); // Изменил на phoneValid
    const [langValid, setLangValid] = useState(true); // Изменил на langValid
    const [termsAgreeValid, setTermsAgreeValid] = useState(true);

    const [success, setSuccess] = useState(false);

    // Очистка полей формы
    const clearInputs = () => {
        setName('');
        setPhone(''); // Очистить поле phone
        setLang(''); // Очистить поле lang
        setNameValid(true);
        setPhoneValid(true);
        setLangValid(true);
        setTermsAgree(false);
        setTermsAgreeValid(true);
    };

    // Функция отправки сообщения в Telegram
    const sendTgMsg = async () => {
        const messageData = {
            name,
            phone, // Изменил на phone
            lang, // Изменил на lang
        };
        try {
            const result = await axios.post('https://civbt.xyz/api/bot/bmjTkaHjev', messageData);
            if (result.status === 200) {
                setSuccess(true);
                clearInputs();
                return true;
            } else {
                alert('Something went wrong. Try again');
                return false;
            }
        } catch (error) {
            if (error.response?.data?.status === 403) {
                alert(error.response.data.message);
                setSuccess(true);
            } else {
                alert(error.message);
            }
        }
    };

    // Проверка поля имени
    const checkName = () => {
        if (name.length > 0) {
            setNameValid(true);
            return true;
        }
        setNameValid(false);
        return false;
    };

    // Проверка поля телефона
    const checkPhone = () => {
        if (phone.length >= 10) { // Простой формат проверки на длину телефона
            setPhoneValid(true);
            return true;
        }
        setPhoneValid(false);
        return false;
    };

    // Проверка поля языка (оно должно быть заполнено)
    const checkLang = () => {
        if (lang.length > 0) {
            setLangValid(true);
            return true;
        }
        setLangValid(false);
        return false;
    };

    // Проверка согласия с условиями
    const checkAgree = () => {
        if (termsAgree) {
            setTermsAgreeValid(true);
            return true;
        }
        setTermsAgreeValid(false);
        return false;
    };

    // Функция для отправки формы
    const submit = () => {
        if (
            checkName() &&
            checkPhone() && // Изменил на checkPhone
            checkLang() && // Изменил на checkLang
            checkAgree()
        ) {
            sendTgMsg();
        }
    };

  return (
    <>
        <div className='flex flex-col xl:flex-row mt-[140px]'>
            <div className='xl:w-1/2 h-[525px] bg-[#37393F]'>
                <div className='max-w-[640px] xl:mx-auto ml-[20px]'>
                    <h1 className='text-[16px] leading-[1.2] mt-[40px] xl:text-[32px] text-[#FFF] rubik-font font-[700] max-w-[582px]'>{t('form.add.1')}</h1>
                    <p className='mt-[20px] text-[#FFFFFFB2] rubik-font text-[14px] xl:text-[16px] font-[300]'>{t('form.add.2')}</p>
                    <div className='flex flex-col max-w-[347px] gap-[10px] mt-[20px] xl:mt-[40px]'>
                        <input 
                            type="text" 
                            className={`h-[45px] px-[25px] bg-[#4A4D55] text-[#FFFFFF80] rubik-font ${!nameValid && 'border-red-500'}`} 
                            placeholder={t('form.add.3')} 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                        />
                        {!nameValid && <span className='text-red-500'>{t('form.error.name')}</span>} {/* Ошибка для имени */}
                        <input 
                            type="text" 
                            className={`h-[45px] px-[25px] bg-[#4A4D55] text-[#FFFFFF80] rubik-font ${!phoneValid && 'border-red-500'}`} 
                            placeholder={t('form.add.4')} 
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)} 
                        />
                         {!phoneValid && <span className='text-red-500'>{t('form.error.phone')}</span>} {/* Ошибка для телефона */}
                        <input 
                            type="text" 
                            className={`h-[45px] px-[25px] bg-[#4A4D55] text-[#FFFFFF80] rubik-font ${!langValid && 'border-red-500'}`} 
                            placeholder={t('form.add.5')} 
                            value={lang} 
                            onChange={(e) => setLang(e.target.value)} 
                        />
                        {!langValid && <span className='text-red-500'>{t('form.error.lang')}</span>} {/* Ошибка для языка */}
                        <div>
                            <input 
                                type="checkbox" 
                                checked={termsAgree} 
                                onChange={(e) => setTermsAgree(e.target.checked)} 
                            />
                            <label className='rubik-font text-white ml-[15px] font-[300] mt-[10px]'>{t('form.add.7')}</label>
                        </div>
                        {!termsAgreeValid && <span className='text-red-500'>{t('form.error.agree')}</span>} {/* Ошибка для согласия */}
                        <button className='w-[170px] h-[45px] bg-[#40DDB6] mt-[10px]' onClick={submit}>
                            <p className='rubik-font text-[16px] xl:text-[17px]'>{t('form.add.6')}</p>
                        </button>
                    </div>

                </div>
            </div>
            <div className='xl:w-1/2 h-[525px]'>
                <img src={eight} className='w-full h-full object-cover' alt="" />
            </div>
        </div>
    </>

  );
}

export default Form;