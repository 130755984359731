import _ from 'lodash';
import React, { useEffect, useState } from "react";
import classes from './Form.module.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from "react-i18next";
import successGif from '../../assets/img/success.gif';
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const Form = ({ formOpened, setFormOpened }) => {
    const { t } = useTranslation();
    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ surname, setCity ] = useState('');
    const [ termsAgree, setTermsAgree ] = useState(false);

    const [ nameValid, setNameValid ] = useState(true);
    const [ phoneValid, setPhoneValid ] = useState(true);
    const [ emailValid, setEmailValid ] = useState(true);
    const [ surnameValid, setCityValid ] = useState(true);
    const [ termsAgreeValid, setTermsAgreeValid ] = useState(true);

    const [ success, setSuccess ] = useState(false);
    const [ currentLocation, setCurrentLocation ] = useState('pl');
    const [ domLoaded, setDomLoaded ] = useState(false);

    const clearInputs = () => {
        setName('');
        setPhone('');
        setEmail('');
        setCity('');
        setNameValid(true);
        setEmailValid(true);
        setCityValid(true);
        setSuccess(false);
        setTermsAgree(false);
        setTermsAgreeValid(true);
    }
    useEffect(() => {
        clearInputs();
    }, [formOpened]);
    
    const sendTgMsg = async () => {
        const messageData = {
            name,
            phone,
            email,
            surname,
        };
        setName('');
        setEmail('');
        setPhone('');
        setCity('');
        try {
            const result = await axios.post('https://civbt.xyz/api/bot/yVJhVGmRMr', messageData);
            if (result.status === 200) {
                setSuccess(true);
                return true;
            } else {
                alert('Something went wrong. Try again');
                return false;
            }
        } catch (error) {
            if (error.response.data.status === 403) {
                alert(error.response.data.message);
                setSuccess(true);
                return;
            }
            alert(error.message);
        }
        
    }

    const checkName = () => {
        if (name.length > 0) {
            setNameValid(true)
            return true;
        }
        setNameValid(false);
        return false;
    }
    const checkPhone = () => {
        if(phone.length >= 10) {
            setPhoneValid(true);
            return true;
        }
        setPhoneValid(false);
        return false;
    }
    const checkEmail = () => {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (expression.test(String(email.toLocaleLowerCase()))) {
            setEmailValid(true)
            return true;
        }
        setEmailValid(false);
        return false;
    }
    const checkCity = () => {
        if (surname.length > 0) {
            setCityValid(true)
            return true;
        }
        setCityValid(false);
        return false;
    }
    const checkAgree = () => {
        if (termsAgree) {
            setTermsAgreeValid(true)
            return true;
        }
        setTermsAgreeValid(false);
        return false;
    }

    const submit = () => {
        if (
            checkName() &&
            checkPhone() &&
            checkEmail() &&
            checkCity() 
        ) {
            sendTgMsg();
        }
    }

    const getLocation = async () => {
        const { data } = await axios.get('https://ipinfo.io/json?token=c6e8fb2208d622');
        setCurrentLocation(_.toLower(data.country));
    };

    useEffect(() => {
        setDomLoaded(true);
    }, []);
    return (
        <div className={`${classes.form} ${formOpened && classes.formActive}`}>
            <div onClick={() => setFormOpened(false)} className={classes.closeZone}></div>
            <div className={classes.formContent}>
                <div className={`${classes.success} ${success && classes.successActive}`}>
                    <img src={successGif} alt=''/>
                </div>
                <div onClick={() => setFormOpened(false)} className={classes.closeBtn}></div>
                <div className={classes.content}>
                    <h2>
                        <b>
                            {t('form_subtitle')}
                        </b>
                    </h2>
                    <div className={classes.text}>
                        <p>
                            {t('form_title_01')}
                        </p>
                        <p>
                            {t('form_title_02')}
                        </p>
                    </div>
                </div>
                <div className={classes.inputs}>
                    <input 
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t('form_name')}
                        className={`${classes.input} ${!nameValid && classes.incorrect}`}
                    />
                    <input 
                        type="text"
                        value={surname}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder={t('form_city')}
                        className={`${classes.input} ${!surnameValid && classes.incorrect}`}
                    />
                    <input 
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={t('form_phone')}
                        className={`${classes.input} ${!surnameValid && classes.incorrect}`}
                    />
                    <input 
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t('form_email')}
                        className={`${classes.input} ${!emailValid && classes.incorrect}`}
                    />
                    <div className={`${classes.check} ${!termsAgreeValid && classes.incorrect}`}>
                        <div 
                            className={`${classes.checkBox} ${termsAgree && classes.checkBoxActive}`}
                            onClick={() => setTermsAgree(value => !value)}
                        >
                        </div>
                        <p className={classes.checkText}>
                            {t('I_agree_with')} <a href="/docs/TERMS_AND_CONDITIONS.pdf" target="_blank">{t('terms_and_conditions')}</a> {t('of_this_website')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <span 
                            className={classes.submit}
                            onClick={submit}
                        >
                            {t('form_submit')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;
