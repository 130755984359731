import React from 'react';

import Fade from 'react-reveal/Fade';
import icon from '../../assets/img/Icon.svg'

import { useTranslation, Trans } from "react-i18next"; 

function Vacancy() {

    const { t } = useTranslation();
  return (
    <>
    <div className='w-[1200px] mx-auto mt-[115px] xl:block sm:hidden hidden'>
    <h1 className='text-[50px] text-[#212121] font-bold text-center rubik-font'>{t('33company_vacancies')}</h1>
        <div className='flex flex-row mt-[60px]'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('34customer_success_manager')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('35active_customer_contact')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('36customer_retention_strategies')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('37product_manager')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('38product_lifecycle_management')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('39strategic_planning_and_communication')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('40business_analyst')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('41business_process_and_data_analysis')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('42report_creation_and_strategic_planning')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px]'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('43researcher')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('44market_competitor_and_trend_research')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('45development_of_reports_and_recommendations')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('46technical_support_specialist')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('47resolution_of_technical_issues_and_customer_support')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('48interaction_with_internal_developers')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('49web_design')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('50development_of_attractive_designs_for_websites_and_apps')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('51implementation_of_graphical_concepts_and_interfaces_with_user_friendly_approach')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px]'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('52financial_analyst')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('53analysis_of_financial_data_and_creation_of_reports')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('54forecasting_financial_trends_and_developing_financial_management_strategies')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('55customer_care')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('56responding_to_questions_solving_problems_and_ensuring_high_customer_satisfaction')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('57collecting_and_analyzing_feedback_for_continuous_service_improvement')}</p>
                </div>
            </Fade>
        </div>
    </div>
    {/* Tablet Block */}
    <div className='mx-auto mt-[115px] xl:hidden sm:block hidden'>
        <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#212121] font-bold text-center rubik-font'>{t('33company_vacancies')}</h1>
        <div className='flex justify-center flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('34customer_success_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('35active_customer_contact')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('36customer_retention_strategies')}</p></div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('37product_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('38product_lifecycle_management')} </p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('39strategic_planning_and_communication')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('40business_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('41business_process_and_data_analysis')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('42report_creation_and_strategic_planning')}</p>
                </div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('43researcher')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('44market_competitor_and_trend_research')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('45development_of_reports_and_recommendations')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('46technical_support_specialist')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('47resolution_of_technical_issues_and_customer_support')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('48interaction_with_internal_developers')}</p>
                </div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('49web_design')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('50development_of_attractive_designs_for_websites_and_apps')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('51implementation_of_graphical_concepts_and_interfaces_with_user_friendly_approach')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('52financial_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('53analysis_of_financial_data_and_creation_of_reports')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('54forecasting_financial_trends_and_developing_financial_management_strategies')}</p>
                </div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('55customer_care')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('56responding_to_questions_solving_problems_and_ensuring_high_customer_satisfaction')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('57collecting_and_analyzing_feedback_for_continuous_service_improvement')}</p>
                </div>
            </Fade>
        </div>
    </div>
    {/* Tablet Block */}
    {/* Mobile Block */}
    <div className='flex flex-col mx-auto mt-[76px] sm:hidden'>
        <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#212121] font-bold text-center rubik-font'>{t('33company_vacancies')}</h1>
        <div className='mt-[50px] mx-auto flex flex-col'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('34customer_success_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('35active_customer_contact')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('36customer_retention_strategies')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>    
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('37product_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('38product_lifecycle_management')} </p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('39strategic_planning_and_communication')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('40business_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('41business_process_and_data_analysis')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('42report_creation_and_strategic_planning')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('43researcher')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('44market_competitor_and_trend_research')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('45development_of_reports_and_recommendations')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('46technical_support_specialist')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('47resolution_of_technical_issues_and_customer_support')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('48interaction_with_internal_developers')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('49web_design')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('50development_of_attractive_designs_for_websites_and_apps')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('51implementation_of_graphical_concepts_and_interfaces_with_user_friendly_approach')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('52financial_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('53analysis_of_financial_data_and_creation_of_reports')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('54forecasting_financial_trends_and_developing_financial_management_strategies')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('55customer_care')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('56responding_to_questions_solving_problems_and_ensuring_high_customer_satisfaction')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('57collecting_and_analyzing_feedback_for_continuous_service_improvement')}</p>
                </div>
            </Fade>
        </div>
    </div>
    <div className='w-full h-[75px] bg-[#40DDB6] flex items-center justify-center mt-[60px] gap-[16px]'>
        <p className='px-[20px] xl:px-0 rubik-font text-[16px] xl:text-[30px] text-[#212121] italic spanbold'><Trans i18nKey="main.add.2" components={[<a href="https://t.me/Work_in_Global_Support" target="_blank" rel="noopener noreferrer" />]} /></p>
        <a className='px-[20px] xl:px-0' href="https://t.me/Work_in_Global_Support"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M19.9997 3.33331C10.7997 3.33331 3.33301 10.8 3.33301 20C3.33301 29.2 10.7997 36.6666 19.9997 36.6666C29.1997 36.6666 36.6663 29.2 36.6663 20C36.6663 10.8 29.1997 3.33331 19.9997 3.33331ZM27.733 14.6666C27.483 17.3 26.3997 23.7 25.8497 26.65C25.6163 27.9 25.1497 28.3166 24.7163 28.3666C23.7497 28.45 23.0163 27.7333 22.083 27.1166C20.6163 26.15 19.783 25.55 18.3663 24.6166C16.7163 23.5333 17.783 22.9333 18.733 21.9666C18.983 21.7166 23.2497 17.8333 23.333 17.4833C23.3446 17.4303 23.343 17.3753 23.3285 17.323C23.314 17.2707 23.2869 17.2228 23.2497 17.1833C23.1497 17.1 23.0163 17.1333 22.8997 17.15C22.7497 17.1833 20.4163 18.7333 15.8663 21.8C15.1997 22.25 14.5997 22.4833 14.0663 22.4666C13.4663 22.45 12.333 22.1333 11.483 21.85C10.433 21.5166 9.61634 21.3333 9.68301 20.75C9.71634 20.45 10.133 20.15 10.9163 19.8333C15.783 17.7166 19.0163 16.3166 20.633 15.65C25.2663 13.7166 26.2163 13.3833 26.8497 13.3833C26.983 13.3833 27.2997 13.4166 27.4997 13.5833C27.6663 13.7166 27.7163 13.9 27.733 14.0333C27.7163 14.1333 27.7497 14.4333 27.733 14.6666Z" fill="#212121"/>
        </svg>
        </a>
    </div>
    </>
    
  );
}

export default Vacancy;